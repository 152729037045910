import { useRouter } from 'next/router';
import React from 'react';

import NEXT from '~/constants/NEXT';
import ROUTES from '~/constants/ROUTES';
import { getAccessToken } from '~/helpers/authTokens';
import getCurrentUrl from '~/helpers/getCurrentUrl';
import Logger from '~/helpers/logger';
import useAuthReactiveTokens from '~/hooks/useAuthReactiveTokens';
import useMyProfile from '~/hooks/useMyProfile';
import useReturnUrl from '~/hooks/useReturnUrl';

import CompleteAccountGuard from './CompleteAccountGuard';

interface IProps {
  children: React.ReactElement<unknown> | React.ReactElement<unknown>[];
  requiresCompleteAccount?: boolean;
}
const logger = new Logger('AuthAccount');

const AuthAccount: React.FC<IProps> = ({ children, requiresCompleteAccount = true }: IProps): React.ReactElement<unknown> | null => {
  const { loading } = useMyProfile();
  const returnUrl = useReturnUrl();
  const router = useRouter();

  const reactiveAccessToken = useAuthReactiveTokens(getAccessToken);

  if (!NEXT.IS_CLIENT || loading) return null;
  if (reactiveAccessToken || getAccessToken()) {
    logger.debug('rendering', children);
    if (requiresCompleteAccount) {
      return <CompleteAccountGuard>{children}</CompleteAccountGuard>;
    }

    return <>{children}</>;
  }

  // Let's give it some time before we give up.
  setTimeout(async () => {
    if (!getAccessToken()) {
      // logout
      const currentPath = getCurrentUrl() || '';
      if (!currentPath.includes(ROUTES.SIGNIN)) {
        await router.push(ROUTES.SIGNIN, {
          query: {
            returnUrl,
          },
        });
      }
    }
  }, 500);

  return null;
};

export default AuthAccount;
