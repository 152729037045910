import { RenderMetaTags as RenderMetaTagsBase, RenderMetaTagsProps } from '@actinc/dls/components/RenderMetaTags';
import Head from 'next/head';
import React from 'react';

import NAMES from '~/constants/NAMES';

const HeadWrapped = (children: React.ReactElement<unknown>[]): React.ReactElement<unknown> => <Head>{children}</Head>;

const MetaTags: React.FC<RenderMetaTagsProps> = ({ title, ...otherProps }: RenderMetaTagsProps): React.ReactElement<RenderMetaTagsProps> => (
  <RenderMetaTagsBase title={title || NAMES.APP} Wrapper={HeadWrapped} {...otherProps} />
);

export default MetaTags;
