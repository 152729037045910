import NEXT from '~/constants/NEXT';
import ROUTES from '~/constants/ROUTES';
import getCurrentUrl from '~/helpers/getCurrentUrl';
import { isPathEquivalent } from '~/helpers/path';
import useQueryParam from '~/hooks/useQueryParam';

const useReturnUrl = (): string | undefined => {
  const queryParam = useQueryParam('returnUrl');

  if (NEXT.IS_CLIENT) {
    const currentUrl = getCurrentUrl() as string;
    return queryParam || (!!currentUrl && !isPathEquivalent(currentUrl, ROUTES.INDEX))
      ? encodeURIComponent(currentUrl)
      : undefined;
  }

  return undefined;
};

export default useReturnUrl;
