import { boolean } from 'boolean';
import { isString } from 'lodash';
import { useRouter } from 'next/router';

// So why is this hook necessary?
//
// Because when rendering the site locally (through node), NextJS does a multi-pass population
// of the router object (including the `query` property), and then sets `isReady` to true when
// it's done. However, when running the site statically (such as through S3), it does it in one
// pass and does NOT set `isReady` to true. So this makes it REALLY tough to know when a query
// param is missing because NextJS hasn't populated it yet vs. when it's actually not present.
// So the following code attempts to pull query params from the `query` property if it can, but
// will manually parse `asPath` if it needs to in order to extract query params itself.

export type UseQueryParamFunction = (
  param: string,
  resultType?: 'string' | 'boolean',
) => boolean | string | string[] | undefined;

export function useQueryParam(param: string, resultType?: 'string'): string | undefined;
export function useQueryParam(param: string, resultType?: 'boolean'): boolean | undefined;
export function useQueryParam(
  param: string,
  resultType?: 'string' | 'boolean',
): boolean | string | string[] | undefined {
  const router = useRouter();
  let result: string | string[] | boolean | undefined;

  if (param in router.query) {
    result = router.query[param];
  } else {
    if (!router.asPath.includes('?')) {
      return undefined;
    }
    if (!router.asPath.includes(param)) {
      return undefined;
    }
    const queryString = router.asPath.split('?')[1];
    const params = queryString
      .split('&')
      .filter(x => x.includes('='))
      .reduce((prev: Record<string, string | string[]>, curr: string): Record<string, string | string[]> => {
        const [key, value] = curr.split('=');
        const next = { ...prev };
        next[key] = prev[key] ? [...prev[key], value] : [decodeURIComponent(value)];
        return next;
      }, {});
    if (param in params) {
      result = params[param];
      result = Array.isArray(result) && result.length === 1 ? result[0] : result;
    }
  }

  if (result === undefined) return result;

  switch (resultType) {
    case 'string':
      return decodeURIComponent(Array.isArray(result) ? result.join('|') : result.toString());
    case 'boolean':
      return boolean(result);
    default:
      return isString(result) ? decodeURIComponent(result) : result;
  }
}

export default useQueryParam;
