import { useRouter } from 'next/router';
import React from 'react';

import Redirect from '~/components/Redirect';
import ROUTES from '~/constants/ROUTES';
import { isPathEquivalent } from '~/helpers/path';
import pickString from '~/helpers/react/pickString';
import useMyProfile from '~/hooks/useMyProfile';
import useReturnUrl from '~/hooks/useReturnUrl';

interface IProps {
  autoRedirect?: boolean;
  children: React.ReactElement<unknown> | React.ReactElement<unknown>[];
}

const CompleteAccountGuard: React.FC<IProps> = ({ autoRedirect = true, children }: IProps): React.ReactElement<unknown> | null => {
  const router = useRouter();
  const returnUrl = useReturnUrl();
  const myProfile = useMyProfile();

  const incompleteProfile = myProfile.data?.isLoggedIn && !myProfile.data?.myProfile?.identity?.dateOfBirth;
  const incompleteSurvey = myProfile.data?.isLoggedIn && !myProfile.data?.completedOnboardingSurvey;

  const targetRoute = pickString(incompleteProfile && ROUTES.COMPLETE_PROFILE, incompleteSurvey && ROUTES.COMPLETE_PROFILE);

  if (autoRedirect && targetRoute && !isPathEquivalent(router.pathname, targetRoute)) {
    return (
      <Redirect
        params={{
          returnUrl,
        }}
        to={targetRoute}
      />
    );
  }

  return <>{children}</>;
};

export default CompleteAccountGuard;
