import { NextPage } from 'next';
import dynamic from 'next/dynamic';
import React from 'react';

import AuthAccount from '~/components/AuthAccount';

import Meta from './meta';

const Page = dynamic(() => import('./Page'), { ssr: false });

const Index: NextPage = (): React.ReactElement<unknown> => (
  <AuthAccount>
    <Page />

    <Meta />
  </AuthAccount>
);

export default Index;
