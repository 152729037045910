/**
 * Helper function to pick a string for props in React elements which are based on conditions.
 * Similar to how JSX allows shortcircuiting elements with '&&', but for props!
 *
 * @example
 *    myProp={pickString(
 *      conditionA && conditionB && 'Matched A and B',
 *      !!requiredString && 'Has the required string'
 *      'Default value'
 *    )}
 */
export default function pickString(...strList: Array<string | null | undefined | false>): string | undefined {
  return strList.find(str => !!str) as string | undefined;
}
